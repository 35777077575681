<template>
    <tbody>
        <template v-if="isLoading">
            <tr>
                <th colspan="3">
                    <b-skeleton width="85%"></b-skeleton>
                </th>
            </tr>
            <tr>
                <th colspan="3">
                    <b-skeleton width="75%"></b-skeleton>
                </th>
            </tr>
            <tr>
                <th colspan="3">
                    <b-skeleton width="65%"></b-skeleton>
                </th>
            </tr>
            <tr>
                <th colspan="3">
                    <b-skeleton width="85%"></b-skeleton>
                </th>
            </tr>
            <tr>
                <th colspan="3">
                    <b-skeleton width="65%"></b-skeleton>
                </th>
            </tr>
            <tr>
                <th colspan="3">
                    <b-skeleton width="75%"></b-skeleton>
                </th>
            </tr>
            <tr>
                <th colspan="3">
                    <b-skeleton width="55%"></b-skeleton>
                </th>
            </tr>
            <tr>
                <th colspan="3">
                    <b-skeleton width="95%"></b-skeleton>
                </th>
            </tr>
            <tr>
                <th colspan="3">
                    <b-skeleton width="85%"></b-skeleton>
                </th>
            </tr>
            <tr>
                <th colspan="3">
                    <b-skeleton width="65%"></b-skeleton>
                </th>
            </tr>
            <tr>
                <th colspan="3">
                    <b-skeleton width="85%"></b-skeleton>
                </th>
            </tr>
            <tr>
                <th colspan="3">
                    <b-skeleton width="55%"></b-skeleton>
                </th>
            </tr>
        </template>
        <template v-for="group in Object.keys(sorted)" v-else>
            <tr class="header-tr"><th colspan="3"><span>{{$t(`services.${group}`)}}</span></th></tr>
            <tr v-for="(service, k) in sorted[group]" :key="service.ind" class="service-tr" :class="{'ml': k%2}">
                <td><img :src="service.icon_url" :alt="service.name"> {{ service.name }}</td>
                <td>
                    <input type="number" 
                        v-if="!und.isUndefined(payload.services_search_rates[service.ind]) && !und.isUndefined(payload.services_search_rates[service.ind].online_result_price)"
                        v-model.number="payload.services_search_rates[service.ind].online_result_price"
                        :class="{ 'is-invalid': !und.isUndefined($v.payload.services_search_rates.$each)
                            && $v.payload.services_search_rates.$each.$iter[service.ind].online_result_price.$invalid }"
                        min="0" class="input-inside">
                </td>
                <td>
                    <input type="number" 
                        v-if="!und.isUndefined(payload.services_search_rates[service.ind]) && !und.isUndefined(payload.services_search_rates[service.ind].offline_result_price)"
                        v-model.number="payload.services_search_rates[service.ind].offline_result_price"
                        :class="{ 'is-invalid': !und.isUndefined($v.payload.services_search_rates.$each)
                            && $v.payload.services_search_rates.$each.$iter[service.ind].offline_result_price.$invalid }"
                        min="0" class="input-inside">
                </td>
            </tr>
            <div style="clear:both"></div>
        </template>
    </tbody>
</template>
<script>
import 'core-js/actual'
import groupObj from "@/helpers/groupObj";
import { validationMixin } from 'vuelidate';
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import _ from "underscore";
export default {
    name: 'ServiceTable',
    mixins: [validationMixin],
    setup() {
        return {
            und: _
        }
    },
    data() {
        return {
            sorted: {},
            payload: {
                services_search_rates: []
            },
            payloadOut: [],
            showing: false,
            isLoading: true,
            validationRules: {
                payload: {
                    services_search_rates: {
                        
                    },
                }
            },
            offline_result_price: 0,
            online_result_price: 0
        }
    },
    validations() {
        return this.validationRules
    },
    computed: {
        ...mapGetters('service_groups', ['services']),
    },
    watch: {
        payload: {
            deep: true,
            handler(val) {
                this.$emit('services', val);
            }
        },
        '$v.$invalid': {
            handler(val) {
                this.$emit('invalid', val);
            }
        }
    },
    methods: {
        sort(){
             this.sorted = Object.keys(this.sorted)
                .sort()
                .reduce((accumulator, key) => {
                    accumulator[key] = this.sorted[key];
                    return accumulator;
                }, {});
        },
        getServices(user_id) {
            return new Promise(resolve => {
              this.$store.dispatch('service_groups/getServices', {
                    order_by: "released_at",
                    order_type: "desc",
                    filters: {
                        user_id
                    }
                }).finally(async () => {
                    const services = this.services.map((service, ind) => {
                        const addObj = {ind};
                        const getService = _.find(this.payloadOut, (ser) => ser.source === service.name);
                        if(!_.isUndefined(getService)) {
                            addObj['offline_result_price'] = getService.offline_result_price;
                            addObj['online_result_price'] = getService.online_result_price;
                        }
                        const retObj = Object.assign(service, addObj);
                        this.payload.services_search_rates[addObj.ind] = {
                            source: service.name,
                            offline_result_price: _.isUndefined(addObj['offline_result_price']) ?  this.offline_result_price: addObj['offline_result_price'],
                            online_result_price: _.isUndefined(addObj['online_result_price']) ? this.online_result_price: addObj['online_result_price']
                        }
                        return retObj;
                    });
                    this.sorted = services.group(groupObj);
                    this.sort();
                    this.$emit('services', this.payload.services_search_rates)
                    resolve();
                })
            })
        },
        setLoading(loading) {
            return new Promise(resolve => {
                this.isLoading = loading
                resolve(loading)
            })
        },
        setValidationRules() {
            return new Promise(resolve => {
                this.validationRules.payload.services_search_rates = {
                    $each: {
                        offline_result_price: { required },
                        online_result_price: { required }
                    }
                }
                resolve()
            })
        },
        setRates(tenant) {
            this.payloadOut = tenant.services_search_rates;
            this.offline_result_price = tenant.search_rate.offline_result_price;
            this.online_result_price = tenant.search_rate.online_result_price;
            this.showing = true;
        }
    },
    mounted() {
        this.$emit('invalid', this.$v.$invalid)
    }
}
</script>
<style>
    .service-tr td {
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
    
    .tableFixHead tbody tr.header-tr {
        padding-top: 10px; 
        margin-top: -1px;       
    } 
    @media screen and (min-width: 992px) {
        .service-tr {
            float: left;
            width: 50%;
        }
        .service-tr:not(.ml) {
            border-right: 1px solid rgb(222 226 230);
        }
        .tableFixHead tbody tr.service-tr:last-child {
            border-bottom: 0;
        }
        .tableFixHead tbody tr.header-tr {
            border-top: 1px solid rgb(222 226 230);
        }

    }

</style>